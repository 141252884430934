'use client';

import { Link } from '@/i18n/routing';
import Image from 'next/image';
import Logo from '../../../public/logo.webp';
import { usePathname } from '@/i18n/routing';
import { useEffect, useState } from 'react';
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { FaXmark, FaBars } from 'react-icons/fa6';
import Smartlook from 'smartlook-client';
import dynamic from 'next/dynamic';
import buynowheader from '../../../public/buynowheader.webp';
import { useTranslations } from 'next-intl';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

const SearchBar = dynamic(() => import('./search-bar'));

export default function Navbar() {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDrOpen, setIsDrOpen] = useState(false);
  const pathname = usePathname();
  const t = useTranslations('navbar');

  const [navLinks, setNavLinks] = useState([
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('latest_insights'),
      href: '/latest-insights',
    },
    {
      name: t('industries'),
      href: '/industries',
    },
    {
      name: t('aboutus'),
      href: '/about-us',
    },
    {
      name: t('contactus'),
      href: '/contact-us',
    },
  ]);

  const [mobileNavLinks, setMobileNavLinks] = useState([
    {
      name: t('home'),
      href: '/',
    },
    {
      name: t('latest_insights'),
      href: '/latest-insights',
    },
    {
      name: t('industries'),
      href: '/industries',
    },
    {
      name: t('aboutus'),
      href: '/about-us',
    },
    {
      name: t('contactus'),
      href: '/contact-us',
    },
    {
      name: t('blogs'),
      href: '/blog',
    },
    {
      name: t('press_releases'),
      href: '/press-releases',
    },
  ]);

  useEffect(() => {
    Smartlook.init('ab3ef3063a9cb85ed3e0ef2f9a79244ff6f8fa82', {
      region: 'eu',
    });

    // const googleTranslateElementInit = () => {
    //   new window.google.translate.TranslateElement(
    //     {
    //       pageLanguage: 'en',
    //       includedLanguages: 'en,fr,de,it,ru,ja,zh-CN,ko,pt-PT',
    //       layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
    //       autoDisplay: true,
    //     },
    //     'google_translate_element'
    //   );
    // };

    // window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  function handleShow() {
    setShow(!show);
  }

  return (
    <Disclosure
      as="nav"
      className={`sticky top-0 z-20 bg-white shadow-sm py-2`}
    >
      {({ open }) => (
        <>
          {pathname.includes('buynow') ? (
            <div className="w-full">
              <Link href="/">
                <Image
                  src={buynowheader}
                  alt="Buy Now Header"
                  title="CoherentMI"
                  priority
                  className="w-full lg:h-16 h-8"
                  sizes="100vw"
                />
              </Link>
            </div>
          ) : (
            <>
              <div className="container-mobile lg:container flex flex-row justify-between items-center relative">
                <div className="md:flex-shrink">
                  <Link href="/">
                    <Image
                      src={Logo}
                      alt="CoherenMI Logo"
                      className="w-52 h-14"
                      title="CoherentMI"
                      sizes="100vw"
                      priority
                    />
                  </Link>
                </div>
                {/* {reportTitle !== '' ? (
                  <div>{reportTitle}</div>
                ) : ( */}
                <div className="flex relative items-center">
                  <ul
                    className={`text-center lg:flex lg:flex-row items-center hidden ${
                      pathname.includes('request-sample') ? 'invisible' : ''
                    }`}
                  >
                    {navLinks.map((link, index) => (
                      <li key={index} className="text-center font-semibold">
                        <Link
                          href={link.href}
                          className={
                            pathname === link.href
                              ? 'text-blue-600 px-4 mb-3'
                              : 'px-3 mb-3'
                          }
                        >
                          {link.name}
                        </Link>
                        <div
                          className={
                            pathname === link.href
                              ? 'bg-blue-600 h-px w-full'
                              : 'hidden'
                          }
                        />
                      </li>
                    ))}
                    <li>
                      <DropdownMenu
                        onOpenChange={(isDrOpen) => setIsDrOpen(isDrOpen)}
                        open={isDrOpen}
                      >
                        <DropdownMenuTrigger>
                          <div className="text-center font-semibold flex items-center space-x-2 hover:cursor-pointer">
                            <span>{t('resource_center')}</span>
                            {isDrOpen ? (
                              <IoMdArrowDropup />
                            ) : (
                              <IoMdArrowDropdown />
                            )}
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem>
                            <a
                              href={'/en/blog'}
                              className={
                                pathname.includes('/blog')
                                  ? 'text-blue-600 font-semibold'
                                  : ''
                              }
                              onClick={() => setIsDrOpen(false)}
                            >
                              {t('blogs')}
                            </a>
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>
                            <a
                              href={'/en/press-releases'}
                              className={
                                pathname.includes('/press-releases')
                                  ? 'text-blue-600 font-semibold'
                                  : ''
                              }
                              onClick={() => setIsDrOpen(false)}
                            >
                              {t('press_releases')}
                            </a>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </li>
                  </ul>
                  {/* google translate Div */}
                  {/* <div
                  id="google_translate_element"
                  className={
                    pathnameHasLocale || pathname.includes('request-sample')
                      ? 'hidden'
                      : 'hidden sm:block'
                  }
                /> */}
                  <button
                    className={
                      show
                        ? `bg-orange-500 text-slate-900 rounded-full p-3 m-2 hover:cursor-pointer`
                        : `md:bg-gray-300 hover:bg-blue-500 text-slate-900 hover:text-white rounded-full p-3 m-2 bg-gradient-to-r from-orange-400 to-pink-500 z-10 hover:cursor-pointer ${
                            pathname.includes('request-sample')
                              ? 'invisible'
                              : ''
                          }`
                    }
                    onClick={() => setShow(true)}
                    aria-label="SearchButton"
                  >
                    <HiOutlineMagnifyingGlass
                      size={18}
                      className="text-white"
                    />
                  </button>
                  {/* Mobile menu button*/}
                  <div className="inset-y-0 right-0 lg:hidden items-center">
                    <DisclosureButton
                      className="relative inline-flex items-center justify-center justify-items-center rounded-md p-2 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setShow(false);
                      }}
                      aria-label="Menu Button"
                    >
                      {isOpen && open ? (
                        <FaXmark className="block h-8 w-8" aria-hidden="true" />
                      ) : (
                        <FaBars className="block h-8 w-8" aria-hidden="true" />
                      )}
                    </DisclosureButton>
                  </div>
                </div>
                {/* )} */}
                {/* <Script
                  src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
                  strategy="lazyOnload"
                  defer={true}
                /> */}
              </div>
              <DisclosurePanel
                className="lg:hidden mobile-menu shadow-xl"
                as="div"
              >
                {({ close }) => (
                  <>
                    <div className="space-y-1 px-2 pb-3 pt-2 flex flex-col gap-y-1 font-medium">
                      {mobileNavLinks.map((item) => (
                        <div key={item.name}>
                          <Link
                            href={item.href}
                            className={
                              pathname === item.href
                                ? 'text-blue-600 px-4'
                                : 'px-4'
                            }
                            aria-current={item.current ? 'page' : undefined}
                            onClick={() => {
                              close();
                            }}
                          >
                            {item.name}
                          </Link>
                          <div
                            className={
                              pathname === item.href
                                ? 'bg-blue-600 h-px w-full'
                                : 'hidden'
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </DisclosurePanel>
              <SearchBar show={show} setShow={handleShow} />
            </>
          )}
        </>
      )}
    </Disclosure>
  );
}
